<script>
/**
 * Widget Component
 */
export default {
  data() {
    return {
      statData: [
        {
          title: "Total Company",
          icon: "ri-building-line",
          value: "0",
        },
        {
          title: "Total Investor",
          icon: "ri-user-line",
          value: "0",
        },
        {
          title: "Total Event",
          icon: "ri-calendar-event-fill",
          value: "0",
        },
        {
          title: "Total Survey",
          icon: "ri-survey-line",
          value: "0",
        },
      ],
    };
  },
  props: ["data"],
  created() {
    this.statData[0].value = this.data.company;
    this.statData[1].value = this.data.investor;
    this.statData[2].value = this.data.event;
    this.statData[3].value = this.data.survey;
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>