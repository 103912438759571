<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { HTTP } from "@/main-source";
import Stat from "./widget";
import Swal from "sweetalert2";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "GalifoPartners",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      statData: null,
      xhrRequest: true,
      list: [],
    };
  },
  created() {
    HTTP.get("general").then((res) => {
      this.statData = res.data;
    });
    this.getLatestEvents();
  },
  methods: {
    getLatestEvents() {
      this.xhrRequest = true;
      this.list = [];
      let uri = "events?limit=8";

      HTTP.get(uri)
        .then((result) => {
          this.list = result.data.rows;
          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Stat :data="statData" v-if="statData != null" />
      </div>
      <div class="col-xl-12">
        <PageHeader :title="'Latest Events'" />
      </div>
      <div class="col-xl-12">
        <div class="row" :class="{ waiting: xhrRequest }">
          <div
            class="spinner-border text-success"
            role="status"
            v-if="xhrRequest"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div class="d-flex firm-lists w-100" v-if="xhrRequest == false">
            <div
              class="col-lg-6 col-xl-3"
              v-for="(item, key) in list"
              :key="key"
            >
              <router-link
                :to="{ name: 'event-detail', params: { id: item.id } }"
              >
                <b-card
                  :img-src="
                    item.profileImg
                      ? $apiDomain + item.profileImg
                      : require('@/assets/images/no-image.jpg')
                  "
                  img-alt="Card image"
                  img-top
                >
                  <b-card-text class="text-center">
                    {{ item.name }}
                  </b-card-text>
                  <div class="row" v-if="$route.name == 'events'">
                    <div class="col">
                      <i class="ri-calendar-2-line"></i>
                      {{ new Date(item.date).toLocaleDateString("en") }}
                    </div>
                    <div class="col">
                      <i class="ri-time-line"></i>
                      {{ new Date(item.date).toLocaleTimeString("en") }}
                    </div>
                  </div>
                </b-card>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>